











import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const state = reactive({
      items: [
        {
          name: "Wydarzenie",
          children: [
            { name: "Nazwa : {{ event.name }}" },
            { name: "Data startu : {{ event.startDate }}" },
            { name: "Data zakończenia : {{ event.endDate }}" },
            { name: "Adres strony : {{ event.website }}" },
            { name: "Prefix : {{ event.prefix }}" },
          ],
        },
        {
          name: "Miejsce wydarzenia",
          children: [
            { name: "Nazwa lokalizacji : {{ event.location.name }}" },
            { name: "Miasto lokalizacji : {{ event.location.city }}" },
            {
              name:
                "Kod pocztowy lokalizacji : {{ event.location.postalCode }}",
            },
            { name: "Ulica lokalizacji : {{ event.location.street }}" },
            {
              name:
                "Numer telefonu lokalizacji : {{ event.location.phoneNumber }}",
            },
            { name: "E-mail lokalizacji : {{ event.location.email }}" },
            { name: "Strona lokalizacji : {{ event.location.website }}" },
            { name: "Kategoria lokalizacji : {{ event.location.category }}" },
            { name: "Opis lokalizacji : {{ event.location.description }}" },
          ],
        },
        {
          name: "Uczestnik",
          children: [
            { name: "Zwrot grzecznościowy : {{ participant.gender }}" },
            { name: "Imię : {{ participant.firstName }}" },
            { name: "Nazwisko : {{ participant.lastName }}" },
            { name: "Adres e-mail : {{ participant.email }}" },
            { name: "Kod uczestnika : {{ participant.code }}" },
            { name: "Numer telefonu : {{ participant.phoneNumber }}" },
            { name: "Tytuł naukowy : {{ participant.degree }}" },
            { name: "Miasto : {{ participant.addressCity }}" },
            {
              name: "Kod pocztowy : {{ participant.addressPostalCode }}",
            },
            {
              name: "Ulica i numer : {{ participant.addressStreet }}",
            },
            { name: "Nazwa instytucji : {{ participant.institutionName }}" },
            { name: "Stanowisko : {{ participant.institutionPosition }}" },
            { name: "Miasto instytucji : {{ participant.institutionCity }}" },
            {
              name:
                "Kod pocztowy instytucji : {{ participant.institutionPostalCode }}",
            },
            {
              name:
                "Ulica i numer instytucji : {{ participant.institutionStreet }}",
            },
            {
              name: "Link do przypisania do grupy : {{{ group:id }}}",
            },
          ],
        },
        // {
        //   name: 'Zakwaterowanie',
        //   children: [
        //     { name: 'Do zapłaty : ---' },
        //     { name: 'Wpłacono : ---' },
        //     { name: 'Pozostało : ---' },
        //     { name: 'Hotel : ---' },
        //     { name: 'Pokój : ---' },
        //     { name: 'Data przyjazdu : ---' },
        //     { name: 'Data wyjazdu : ---' },
        //     { name: 'Dane współlokatora : ---' }
        //   ],
        // },
        {
          name: "Rejestracja",
          children: [
            {
              name:
                "Do zapłaty : {{ participant.participantRegistrationFeeTerm.toPay }}",
            },
            {
              name:
                "Wpłacono : {{ participant.participantRegistrationFeeTerm.paid }}",
            },
            {
              name:
                "Pozostało : {{ participant.participantRegistrationFeeTerm.leftToPay }}",
            },
            { name: "Tytuł wpłaty : {{ participant.code }}" },
            {
              name:
                "Data płatności: {{ participant.participantRegistrationFeeTerm.paymentDate }}",
            },
            { name: "Przycisk PayU: {{{ paymentButton }}}" },
            {
              name: "Właściciel konta bankowego PL: {{ bankAccountPl.owner }} ",
            },
            { name: "Nazwa banku PL: {{ bankAccountPl.bankName }} " },
            {
              name:
                "Numer konta bankowego PL: {{ bankAccountPl.accountNumber }} ",
            },
            {
              name: "Właściciel konta bankowego EN: {{ bankAccountEn.owner }} ",
            },
            { name: "Nazwa banku EN: {{ bankAccountEn.bankName }} " },
            {
              name:
                "Numer konta bankowego EN: {{ bankAccountEn.accountNumber }} ",
            },
          ],
        },
        {
          name: "Faktura",
          children: [
            { name: "Instytucja : {{ participant.invoiceName }}" },
            { name: "Ulica : {{ participant.invoiceStreet }}" },
            { name: "Kod pocztowy : {{ participant.invoicePostalCode }}" },
            { name: "Miasto : {{ participant.invoiceCity }}" },
            { name: "Nip : {{ participant.invoiceVatNumber }}" },
            {
              name:
                "Do zapłaty : {{ participant.participantRegistrationFeeTerm.toPay }}",
            },
            {
              name:
                "Wpłacono : {{ participant.participantRegistrationFeeTerm.paid }}",
            },
            {
              name:
                "Pozostało : {{ participant.participantRegistrationFeeTerm.leftToPay }}",
            },
          ],
        },
        {
          name: "Streszczenia",
          children: [
            { name: "Tytuł streszczenia : {{ abstract.title }}" },
            {
              name:
                "Aby dodać listę autorów należy użyć pętli {{#each abstract.authors}} i zakończyć {{/each}}",
            },
            { name: "Imię autora : {{ this.firstName }}" },
            { name: "Nazwisko autora : {{ this.lastName }}" },
            { name: "Mail autora : {{ this.email }}" },
            { name: "Adres autora : {{ this.address }}" },
            { name: "Instytucja autora : {{ this.affiliation }}" },
          ],
        },
        {
          name: "Pozostałe",
          children: [
            { name: "Dodaj do kalendarza Google : {{{ calendarButtons }}}" },
            { name: "Przycisk do płatności : {{{ paymentButton }}}" },
            {
              name:
                "Link do płatności (dodać na wypadek nie wyświetlenia się przycisku) : {{{ paymentLink }}}",
            },
            {
              name:
                "Link do ponownie wygenerowanej płatności : {{{ newPaymentLink }}}",
            },
            {
              name:
                "Link do auto logowania do panelu uczestnika : {{{ autoLoginLink }}}",
            },
            {
              name: `Przykładowy kod wyświetlania warunkowego :
              {{#ifCond participant.state 'participant' }}
                <div> Treść do wyświetlenia </div>
              {{/ifCond}}`,
            },
            { name: "Kod QR z kodem uczestnika : {{{ qrCode }}}" },
            { name: "Jednorazowy kod rabatowy : {{{ discount:id }}}" },
            { name: "Link do ankiety: {{{ survey:ID Ankiety }}}" },
            { name: "Panelista start: {{ panelista-start }}" },
            { name: "Panelista stop: {{ panelista-stop }}" },
            { name: "Wykładowca start: {{ wykladowca-start }}" },
            { name: "Wykładowca stop: {{ wykladowca-stop }}" },
            { name: "Prowadzący start: {{ prowadzacy-start }}" },
            { name: "Prowadzący stop: {{ prowadzacy-stop }}" },
          ],
        },
      ],
    });
    return { state };
  },
});
